<template>
  <div>
    <div class="d-lg-none" style="height: 15px;"></div>
    <div class="d-none d-lg-block">
      <div class="kt-subheader kt-grid__item" id="kt_subheader"
        :style="`background: ${ layoutConfig().colors.state.brand };`">
        <div class="kt-container" v-bind:class="{ 'kt-container--fluid': widthFluid }">
          <div :class="
            'kt-subheader__main ' +
            (!$tools.isNullOrUndefined($route.meta) &&
              !$tools.isNullOrUndefined($route.meta.serviceSelector) &&
              !$tools.isNullOrUndefined($route.meta.serviceSelector.services) &&
              hasServiceSelector
              ? 'subheader__main-margintop'
              : '')
          " v-if="config.subheader.crumbs.display !== false">
            <h3 class="kt-subheader__title">
              {{ title }}
            </h3>
            <div class="kt-subheader__breadcrumbs">
              <!-- <router-link :to="'/'" class="kt-subheader__breadcrumbs-home">
            <i class="flaticon2-shelter"></i>
          </router-link> -->
              <span class="kt-subheader__desc">{{
                  currentUser.clients[clientId].name
              }}</span>

              <template v-for="(breadcrumb, i) in breadcrumbsNoTitle">
                <span :key="`${ i }-${ breadcrumb.id }`" class="kt-subheader__breadcrumbs-separator"></span>
                <router-link v-if="breadcrumb.route" :key="i" :to="breadcrumb.route"
                  class="kt-subheader__breadcrumbs-link">
                  {{ breadcrumb.title }}</router-link>
                <span class="kt-subheader__desc" :key="i" v-if="!breadcrumb.route">{{ breadcrumb.title }}</span>
              </template>
            </div>
          </div>
          <div class="kt-subheader__toolbar">
            <div
              style="display: flex;height: 54px;place-content: center flex-end;align-items: center;justify-content: flex-end;align-content: center;flex-direction: row; margin-right: -10px;">
              <div
                :style="(showAccountWarning === true ? `background: var(--warning);` : '') + 'display: flex; height: calc(100% - 14px); flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center; margin: 7px -8px 7px 7px; border-radius: 50px;'">
                <div style="padding: 0 40px 0 40px; color: var(--dark); font-weight: 600;" v-if="
                  showAccountWarning === true
                ">
                  Your account has an overdue invoice <span v-if="$route.path !== '/MyAccount/Invoices'">- <router-link
                      to="/MyAccount/Invoices">view</router-link></span>
                </div>
              </div>
              <div v-show="currentBalanceShow"
                :style="`background: var(--${ currentBalanceColor }); margin: 7px 0 7px 25px;   display: flex; height: calc(100% - 14px); flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center; border-radius: 50px;`"
                v-if="
                  !$tools.isNullOrUndefined($route.meta) &&
                  $route.meta.showBalance !== false
                ">
                <Statement @currentBalance="currentBalance"
                  style="padding: 0 10px 0 10px; color: var(--dark); font-weight: 600;" :displayOnlyTotal="true"
                  :hideCurrentBalanceText="true">
                  <template v-slot:currentBalanceValue="slot">{{
                      slot.currentBalance
                  }}<span v-if="canTopup"> - <router-link to="/MyAccount/Invoices">top-up</router-link>
                    </span></template>
                </Statement>
              </div>
              <ServicesSelector style="height: calc(100% - 1px); margin: 7px 0 7px 10px;"
                @hasServices="updateSSelector"></ServicesSelector>
              <div style="height: calc(100% - 1px); margin: 7px 0 7px 10px;" v-if="false
              ">
                <template v-if="config.subheader.actions.display !== false && false">
                  <a href="#" class="btn kt-subheader__btn-primary">
                    Actions &nbsp;
                    <!--<i class="flaticon2-calendar-1"></i>-->
                  </a>

                  <div class="dropdown dropdown-inline" v-b-tooltip.hover.left="'Quick actions'">
                    <a href="#" class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--md'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 14H9a1 1 0 010-2h2v-2a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2z" fill="#000"/></g></svg>
                      <!--<i class="flaticon2-plus"></i>-->
                    </a>
                    <div class="
                  dropdown-menu
                  dropdown-menu-fit
                  dropdown-menu-md
                  dropdown-menu-right
                ">
                      <!--begin::Nav-->
                      <ul class="kt-nav">
                        <li class="kt-nav__head">
                          Add anything or jump to:
                          <i class="flaticon2-information" v-b-tooltip.hover.bottom="'Click to learn more...'"></i>
                        </li>
                        <li class="kt-nav__separator"></li>
                        <li class="kt-nav__item">
                          <a href="#" class="kt-nav__link">
                            <i class="kt-nav__link-icon flaticon2-drop"></i>
                            <span class="kt-nav__link-text">Order</span>
                          </a>
                        </li>
                        <li class="kt-nav__item">
                          <a href="#" class="kt-nav__link">
                            <i class="kt-nav__link-icon flaticon2-calendar-8"></i>
                            <span class="kt-nav__link-text">Ticket</span>
                          </a>
                        </li>
                        <li class="kt-nav__item">
                          <a href="#" class="kt-nav__link">
                            <i class="kt-nav__link-icon flaticon2-telegram-logo"></i>
                            <span class="kt-nav__link-text">Goal</span>
                          </a>
                        </li>
                        <li class="kt-nav__item">
                          <a href="#" class="kt-nav__link">
                            <i class="kt-nav__link-icon flaticon2-new-email"></i>
                            <span class="kt-nav__link-text">Support Case</span>
                            <span class="kt-nav__link-badge">
                              <span class="kt-badge kt-badge--success">5</span>
                            </span>
                          </a>
                        </li>
                        <li class="kt-nav__separator"></li>
                        <li class="kt-nav__foot">
                          <a class="btn btn-label-brand btn-bold btn-sm" href="#">Upgrade plan</a>
                          <a class="btn btn-clean btn-bold btn-sm" href="#"
                            v-b-tooltip.hover.bottom="'Click to learn more...'">Learn more</a>
                        </li>
                      </ul>
                      <!--end::Nav-->
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Statement from "../../pages/my-account/statement.vue";
import ServicesSelector from './ServicesSelector';
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";

export default {
  name: "KTSubheader",
  components: {
    Statement,
    ServicesSelector
  },
  props: {
    showAccountWarning: Boolean,
    breadcrumbs: Array,
    title: String
  },
  data() {
    return {
      hasServiceSelector: false,
      currentBalanceShow: false,
      currentBalanceColor: 'light',
      canTopup: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser", "clientId", "theme"]),

    breadcrumbsNoTitle() {
      let tNArr = [];

      for (let bc of this.breadcrumbs) {
        if (bc.title !== this.title) tNArr.push(bc);
      }

      return tNArr;
    },
    /**
     * Check if subheader width is fluid
     */
    config() {
      return this.layoutConfig();
    },
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },
  },
  methods: {
    currentBalance(x) {
      this.currentBalanceShow = x.label !== null;
      this.currentBalanceColor = 'light';
      if (this.currentBalanceShow) {
        if (x.value > 10)
          this.currentBalanceColor = 'success';
        if (x.value == 0)
          this.currentBalanceColor = 'warning';
        if (x.value < 0)
          this.currentBalanceColor = 'danger';
      }
    },
    updateSSelector(d) {
      this.hasServiceSelector = d;
    }
  }
};
</script>

<style scoped>
/* @media all and (min-width: 1024px) {
  .kt-subheader__main.subheader__main-margintop {
    margin-top: -15px;
  }
} */
@media all and (max-width: 1024px) {
  .kt-subheader {
    border-radius: 15px !important;
  }
}

@media all and (max-width: 735px) {
  .kt-subheader>.kt-container {
    justify-content: center;
  }
}
</style>